.central-frame {
    border-radius: 20px;
    display: flex;
    width: 500px;
    flex-direction: column;
    margin-top: 10%;
    padding: 10px;
    background-color: var(--white);
    /* box-shadow: 5px 10px var(--grey-100) */
}

.internal-frame{
    color: var(--grey-500);
    font-weight: 900;
    display:flexbox;
    flex-direction: row;
    margin: 10px;
}

.green-frame, .amount-green-box{
    border: solid 2px;
    border-color: var(--emmet-green);
    border-radius: 10px;
    display: flex;
    justify-content:space-evenly;
    padding: 10px;
}

.invisible-frame{
    display: flex;
    justify-content:space-evenly;
    padding: 10px;
}

.align-center{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: -5px;
    margin-bottom: -20px;
}

.justify-left {
    display: flex;
    justify-content: flex-start;
}

.internal-divider{
    border: solid 2px;
    border-radius: 2px;
    border-color: var(--grey-500);
    margin: 0 5px 0 5px;
}

.transaction-hash-link {
    margin-left: 5px;
    color:cornflowerblue;
}

.success, .failure {
    margin-left: 5px;
}

.success {
    color: var(--emmet-green);
}

.failure {
    color:deeppink;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.amount-input, .destination-address {
    padding: 10px;
    outline: none;
    width: 60%;
    font-size: 1em !important;
    font-weight: 600;
    color: var(--grey-600) !important;
    border-radius: 10px;
    border-color: var(--grey-100);
    background-color: var(--white) !important;
}

.amount-input::placeholder, .destination-address::placeholder{
    color: var(--grey-400);
    font-weight: 500;
}

.justify-sides{
    justify-content: space-between;
    align-items: center;
}

.max-button{
    color: var(--grey-600);
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
    margin-left: 5px;
}

.group-label {
    margin: 0 0 10px 5px;
    color: var(--emmet-green)
}

.margin-right{
    margin-right: 80px;
}

.minus-top-margin{
    margin-top: -10px;
}

.balance-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items:flex-start;
    min-width: 200px;
}

.align-end{
    align-items: end;
}

.text-align-left {
    text-align: left;
}

.swap-image{
    cursor: pointer;
}

.action-button{
    background-color:goldenrod;
    border: solid 1px grey;
    border-radius: 10px;
    color:white;
    font-size:1em;
    font-weight: 600;
    padding:15px 30px;
    cursor: pointer;
    align-content: center;
    text-align: center;
}

button[disabled]{
    pointer-events: none;
    background-color: grey;
    opacity: 0.7;
    cursor:auto;
}

button:active{
    box-shadow: 0 0 5px -1px rgba(0,0,0,0.6);
}

.vertical-push{
    margin-top: 30px;
}

.no-margin{
    margin:0
}

.push-sides {
    width:20%;
}

@media screen and (max-width: 550px) {
    .central-frame {
        width: 90%;
        margin-top: 5px;
    }
    .green-frame{
        flex-direction:column-reverse;
        font-size: 1em;
    }
    .amount-input, .destination-address{
        width:65%;
    }
    .internal-divider, .vertical-push{
        margin: 5px 0 5px 0;
    }
    .invisible-frame{
        display: flex;
        flex-direction: column;
        justify-content:center;
        flex-wrap: wrap;
        align-content: center !important;
        padding:10px;
    }
    .balance-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items:center;
        
    }
    .swap-image{
        display: flex;
        align-self: center;
    }

    .action-button{
        margin: 5px 0 5px 0;
        padding:5px 40px;
        height: 50px;
    }

    .mobile-invisible {
        display: none;
        visibility: hidden;
    }

    .max-button{
        padding: 0 15px;
    }
}