.dropdown-box{
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    align-items: center;
    cursor: pointer;
    border: solid 1px;
    border-radius: 10px;
    border-color: var(--grey-200);
    font-size: 0.7em;
}

.dropdown-image{
    height:30px;
    width:30px;
    margin:5px 10px 5px 10px;
}

.dropdown-text{
    font-size: 2em;
    min-width: 110px;
    text-align: center;
}

.dropdown-caret-down{
    margin: 5px 15px 5px 15px;
    font-size: 2em;
}

.internal-box{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-weight: 600;
    min-width: 220px;
}

.internal-label {
    margin: 0 0 0 10px;
}

.image-placeholder{
    min-width: 30px;
}