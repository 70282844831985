main {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    margin-bottom: -55px;
}

@media screen and (max-width: 550px) {

    main{
        margin-bottom:50px;
    }

}

@media screen and (max-width: 395px) {

    main{
        margin-bottom:200px;
    }

}

@media screen and (max-width: 375px) {

    main{
        margin-bottom:255px;
    }

}