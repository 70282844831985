:root {
  /* Primary colors */
  --blue: #0d6efd;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #d63384;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #198754;
  --teal: #20c997;
  --cyan: #0dcaf0;
  --emmet-green: #35CE8D;
  /* grayscale */
  --white: #fff;
  --grey-100: #f8f9fa;
  --grey-200: #e9ecef;
  --grey-300: #dee2e6;
  --grey-400: #ced4da;
  --grey-500: #adb5bd;
  --grey-600: #6c757d;
  --grey-700: #495057;
  --grey-800: #343a40;
  --grey-900: #212529;
  --black: #000;
  /* Bootstrap colors */
  --primary: var(--blue);
  --secondary: var(--grey-600);
  --success: var(--green);
  --info: var(--cyan);
  --warning: var(--yellow);
  --danger: var(--red);
  --light: var(--grey-100);
  --dark: var(--grey-900);
  /* GRADIENT */
  --gradient: linear-gradient(180deg, rgba(var(--white), .15), rgba(var(--white), 0));
}

body {
  margin: 0;
  padding: 0;
  background-color: var(--grey-200);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button{
  height:5vh;
  border:var(--white);
  border-radius:0.5rem;
  padding:5px 20px;
  color:var(--dark)
}