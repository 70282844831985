.dropdown-wrapper {
    background-color: var(--white);
    border: solid 1px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 10px;
    position:absolute;
}

.dropdown-element {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: 1.5em;
}

.dropdown-element:hover{
    background-color: var(--emmet-green);
    color:var(--white)
}