.app {
  box-sizing: border-box;
  display: flexbox;
  min-height: 100vh;
  flex-direction: column;
  padding: 0;
  margin: 0;
  height: 95vh;
  min-height: 95%;

  /* Equal to height of footer */
  /* But also accounting for potential margin-bottom of last child */
  margin-bottom: -50px;
}